import quickFetch from 'quick-fetch';
import { TreatmentsClient } from '../api/experimentsApis';
import { earlyRequestHubUserAttributes } from 'frontend-preferences-client/quickFetch';
// getQueryObject setup starts
let queryParamItems;
let queryParamItemPair;
let counter = 0;
let key;
let value;
// getQueryObject setup ends

const navEntity = window.performance.getEntriesByType('navigation');
const isBackForwardNavigation = navEntity && navEntity[0] && navEntity[0].type === 'back_forward';
function getQueryParam(paramName) {
  queryParamItems = window.location.search.substring(1).split('&');
  for (counter; counter < queryParamItems.length; counter++) {
    queryParamItemPair = queryParamItems[counter].split('=');
    key = decodeURIComponent(queryParamItemPair[0]);
    value = decodeURIComponent(queryParamItemPair[1] || '');
    if (key === paramName) {
      return value;
    }
  }
  return undefined;
}
function makeRequest(requestKey, url, method = 'GET', body, headers) {
  let extraHeaders = [{
    header: 'Accept',
    value: 'application/json'
  }];
  if (headers) {
    extraHeaders = extraHeaders.concat(headers);
  }
  if (isBackForwardNavigation) {
    /**
     * User may get old task and onboarding settings from cache if switched view before back-forward navigation.
     * Add timestamp to the request to prevent caching from the back-forward navigation.
     */
    url += `${url.indexOf('?') > -1 ? '&' : '?'}_t=${Date.now()}`;
  }
  quickFetch.makeEarlyRequest(requestKey, {
    url,
    timeout: 14000,
    dataType: 'json',
    contentType: 'application/json',
    type: method,
    data: body || null,
    extraHeaders
  });
}
function fetchOnboardingSettings() {
  makeRequest('OnboardingSettingsRequest', quickFetch.getApiUrl('/onboarding-settings/v1/settings/', true), 'GET');
}
function fetchProgress(groupKey = '') {
  makeRequest(`OnboardingProgress${groupKey ? `:${groupKey}` : ''}`, quickFetch.getApiUrl(`/usercontext-app/v1/onboarding/progress/${groupKey}`, true));
}
function fetchTasks(groupKey = '') {
  makeRequest(`OnboardingTasks${groupKey ? `:${groupKey}` : ''}`, quickFetch.getApiUrl(`/usercontext-app/v1/onboarding/tasks/${groupKey}`, true));
}
function fetchDemographics() {
  makeRequest('DemographicsRequest', quickFetch.getApiUrl('/demographics/v1', true), 'GET');
}
function fetchSeatsAccess() {
  makeRequest('SeatsAccessQuickFetchKey', quickFetch.getApiUrl('/app-users/v1/seat-assignments/self', true), 'GET');
}
function fetchGrowthPlanAttributes() {
  earlyRequestHubUserAttributes(['GrowthOnboardingSetup:ACQ0143NewSignupOnboardingFlowTreatment', 'GrowthCoaching:COAC0001Treatment', 'GrowthCoaching:COAC0002Treatment'], 'hub-user-attributes-early-request-growth-plan');
}
function fetchGrowthPostPurchaseGpp0001PortalExperiment() {
  const portalId = quickFetch.getPortalId();
  makeRequest('GrowthPostPurchasePortalExperiment', quickFetch.getApiUrl(`/growth-post-purchases/v1/portal-experiments/${portalId}/gpp-0001`, true));
}
if (quickFetch) {
  const maybeGroupKeyOverride = getQueryParam('groupKey');
  fetchOnboardingSettings();
  fetchTasks(maybeGroupKeyOverride);
  fetchProgress(maybeGroupKeyOverride);
  fetchDemographics();
  fetchSeatsAccess();
  fetchGrowthPlanAttributes();
  fetchGrowthPostPurchaseGpp0001PortalExperiment();
  TreatmentsClient.get().resolve();
}