module.exports = {
  "gpp-0002": {
    "identifierType": "PORTAL_ID",
    "parameters": {
      "group": [
        "control",
        "variant"
      ]
    }
  }
};